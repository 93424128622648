import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Button,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { createCardToken } from "../fincode";
import { Plan, useCreateUserAndPaymentMutation } from "../graphql/generated/apollo";
import CreditCardForm from "./CreditCardForm";

type Props = {
  trialUser: boolean;
  onlyEnterprisePlan: boolean;
};
export default function ApplyForm({ trialUser, onlyEnterprisePlan }: Props) {
  const defaultPlan = onlyEnterprisePlan ? "ENTERPRISE_PLAN" : "STARTER_PLAN";
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    setValue,
    reset,
  } = useForm<ApplyFormType>({
    defaultValues: {
      plan: defaultPlan,
    },
  });

  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [createUserAndPaymentMutation] = useCreateUserAndPaymentMutation();

  const divFormControll = {
    mb: "8",
  };

  const labelFormControl = {
    borderRadius: "md",
  };

  const inputFormControl = {
    borderRadius: "md",
    borderColor: "gray.300",
  };

  const submitButton = {
    bg: "#333",
    w: "100%",
    padding: "6",
  };

  const errorMessage = {
    color: "#f23a3c",
    textAlign: "left",
    fontSize: "small",
  };

  async function onSubmit(values: ApplyFormType) {
    try {
      const card = {
        card_no: values.cardNumber.toString(),
        expire: formatDate(values.expirationMonth, values.expirationYear),
        holder_name: values.billingName,
        security_code: values.securityCode.toString(),
        number: "1",
      };

      const res = await createCardToken(card);
      const resData = await res.json();

      if (res.status !== 200) {
        throw new Error("クレジットカードが無効です。入力内容が正しいかお確かめください。");
      }
      const token = resData.list[0].token as string;

      let plan: Plan;
      if (values.plan === "STARTER_PLAN") {
        plan = Plan.Starter;
      } else if (values.plan === "GROWTH_PLAN") {
        plan = Plan.Growth;
      } else {
        plan = Plan.Enterprise;
      }

      console.log(plan);
      try {
        const result = await createUserAndPaymentMutation({
          variables: {
            input: {
              token: token,
              plan: plan,
              shopName: values.shopName,
              ownerName: values.ownerName,
              linkUrl: values.linkUrl,
              mailAddress: values.mailAddress,
              phoneNumber: values.phoneNumber,
              password: values.password,
              trialUser: trialUser,
            },
          },
        });
        if (result.errors !== undefined) {
          reset();
          onOpen();
          throw new Error("createUserAndPaymentMutation失敗");
        }
      } catch {
        reset();
        onOpen();
        throw new Error("createUserAndPaymentMutation失敗");
      }

      toast({
        description: "申請が完了しました。",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      reset();
      navigate("/");
    } catch (error) {
      if (error instanceof Error && error?.message !== "createUserAndPaymentMutation失敗") {
        toast({
          description: error instanceof Error ? error.message : "An unknown error occurred",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
    }
  }

  return (
    <>
      <form>
        <FormControl sx={divFormControll}>
          <FormLabel sx={labelFormControl}>
            <Text fontSize="sm">
              ショップ名{" "}
              <Text as="span" color="#f23a3c">
                *
              </Text>
            </Text>
          </FormLabel>
          <Input
            marginTop="0px"
            sx={inputFormControl}
            placeholder="Easy Vintage"
            {...register("shopName", {
              required: "この項目は必須です",
            })}
          />
          <Text sx={errorMessage}>{errors.shopName?.message}</Text>
        </FormControl>
        <FormControl sx={divFormControll}>
          <FormLabel sx={labelFormControl}>
            <Text fontSize="sm">
              ご担当者さま氏名{" "}
              <Text as="span" color="#f23a3c">
                *
              </Text>
            </Text>
          </FormLabel>
          <Input
            marginTop="0px"
            sx={inputFormControl}
            placeholder="楽出 太郎"
            {...register("ownerName", {
              required: "この項目は必須です",
            })}
          />
          <Text sx={errorMessage}>{errors.ownerName?.message}</Text>
        </FormControl>
        <FormControl sx={divFormControll}>
          <FormLabel sx={labelFormControl}>
            <Text fontSize="sm">
              メールアドレス{" "}
              <Text as="span" color="#f23a3c">
                *
              </Text>
            </Text>
          </FormLabel>
          <Input
            type="email"
            marginTop="0px"
            sx={inputFormControl}
            placeholder="taro.takude@easyvintage.com"
            {...register("mailAddress", {
              required: "この項目は必須です",
            })}
          />
          <Text sx={errorMessage}>{errors.mailAddress?.message}</Text>
        </FormControl>
        <FormControl sx={divFormControll}>
          <FormLabel sx={labelFormControl}>
            <Text fontSize="sm" marginBottom="8px">
              パスワード{" "}
              <Text as="span" color="#f23a3c">
                *
              </Text>
            </Text>
            <Text fontSize="xs" color="#444">
              半角英字、数字、記号が使用できます。6文字以上30文字以下で入力してください。
            </Text>
          </FormLabel>
          <Input
            type="password"
            marginTop="0px"
            sx={inputFormControl}
            {...register("password", {
              required: "この項目は必須です",
              minLength: {
                value: 6,
                message: "パスワードは6文字以上で入力してください",
              },
              maxLength: {
                value: 30,
                message: "パスワードは30文字以下で入力してください",
              },
              pattern: {
                value: /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/,
                message: "パスワードは英字と数字を含めるようにしてください",
              },
            })}
          />
          <Text sx={errorMessage}>{errors.password?.message}</Text>
        </FormControl>
        <FormControl sx={divFormControll}>
          <FormLabel sx={labelFormControl}>
            <Text fontSize="sm">
              電話番号{" "}
              <Text as="span" color="#f23a3c">
                *
              </Text>
            </Text>
          </FormLabel>
          <Input
            type="tel"
            marginTop="0px"
            sx={inputFormControl}
            placeholder="090-1234-5678"
            {...register("phoneNumber", {
              required: "この項目は必須です",
            })}
          />
          <Text sx={errorMessage}>{errors.phoneNumber?.message}</Text>
        </FormControl>
        <FormControl sx={divFormControll}>
          <FormLabel sx={labelFormControl}>
            <Text fontSize="sm">
              ショップのInstagram/ホームページ{" "}
              <Text as="span" color="#f23a3c">
                *
              </Text>
            </Text>
          </FormLabel>
          <Input
            type="url"
            marginTop="0px"
            sx={inputFormControl}
            placeholder="https://www.instagram.com/easy_vintage"
            {...register("linkUrl", {
              required: "この項目は必須です",
            })}
          />
          <Text sx={errorMessage}>{errors.linkUrl?.message}</Text>
        </FormControl>
        <FormControl sx={divFormControll}>
          <FormLabel sx={labelFormControl}>
            <Text fontSize="sm">
              申し込みプラン（税別）{" "}
              <Text as="span" color="#f23a3c">
                *
              </Text>
            </Text>
          </FormLabel>
          <RadioGroup
            onChange={(value: PlanType) => setValue("plan", value)}
            defaultValue={defaultPlan}
            marginTop="12px"
            textAlign="left"
          >
            {onlyEnterprisePlan ? (
              <Radio fontSize="sm" value="ENTERPRISE_PLAN" {...register("plan", { required: "この項目は必須です" })}>
                {`エンタープライズプラン（月額14,980円+サービス利用料1%${trialUser ? "、ご契約初月無料" : ""}）`}
              </Radio>
            ) : (
              <>
                <Radio
                  fontSize="sm"
                  marginBottom="4px"
                  value="STARTER_PLAN"
                  {...register("plan", { required: "この項目は必須です" })}
                >
                  {`スタータープラン（月額980円+サービス利用料7%${trialUser ? "、ご契約初月無料" : ""}）`}
                </Radio>
                <Radio
                  fontSize="sm"
                  marginBottom="4px"
                  value="GROWTH_PLAN"
                  {...register("plan", { required: "この項目は必須です" })}
                >
                  {`グロースプラン（月額4,980円+サービス利用料3%${trialUser ? "、ご契約初月無料" : ""}）`}
                </Radio>
              </>
            )}
          </RadioGroup>
          <Text sx={errorMessage}>{errors.plan?.message}</Text>
        </FormControl>
        <CreditCardForm register={register} errors={errors} />
      </form>
      <Text fontSize="sm" align="left" marginBottom="12px" marginTop="72px">
        <Link href="https://reliable-toad.super.site/terms" isExternal>
          利用規約
        </Link>
        ・
        <Link href="https://reliable-toad.super.site/privacy" isExternal>
          プライバシーポリシー
        </Link>
        に同意の上、お申し込みください
      </Text>
      <Button onClick={handleSubmit(onSubmit)} isLoading={isSubmitting} isDisabled={isSubmitting} sx={submitButton}>
        <Text color="#fff" fontSize="large">
          ラクダスの利用申込をする <Icon as={ArrowForwardIcon} w={5} h={5} />
        </Text>
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>申請に失敗しました</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={4}>現在、問題を解決するために対応中です。解決次第、速やかにご連絡いたします。</Text>
            <Text mb={4}>ご不便をおかけして申し訳ありませんが、少々お待ちください。</Text>
            <Text>何かご不明点やご質問がございましたら、以下のメールアドレスまでお問い合わせください：</Text>
            <Text fontWeight="bold">dev+inquiry@rakudasu.com</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export type ApplyFormType = {
  shopName: string;
  ownerName: string;
  mailAddress: string;
  password: string;
  phoneNumber: string;
  linkUrl: string;
  plan: PlanType;
  trialUser: boolean;
} & CreditCardFormType;

type PlanType = "STARTER_PLAN" | "GROWTH_PLAN" | "ENTERPRISE_PLAN";
export type CreditCardFormType = {
  cardNumber: number;
  expirationYear: string;
  expirationMonth: string;
  billingName: string;
  securityCode: number;
};

function formatDate(month: string, year: string): string {
  // 年の最後の2桁を取得
  const yearLastTwo = year.slice(-2);
  // 月が1桁の場合に0を追加
  const formattedMonth = month.padStart(2, "0");
  return yearLastTwo + formattedMonth;
}
